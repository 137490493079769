import {DialogContent, HStack, Input, Stack} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import {Field} from "./ui/field";
import {Button} from "./ui/button";
import {updateOrganization} from "../api/organization";

// @ts-ignore
const UpdateOrganizationModel = ({isUpdateModelOpened, setIsUpdateModelOpened, fetchData, organization}) => {
    const [name, setName] = useState("");
    const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const [isAddError, setIsAddError] = useState(false);

    useEffect(() => {
        setIsAddButtonDisable(!(name != ""));
    }, [name])

    const updateOrganizationHandler = async () => {
        setIsAddButtonLoading(true)
        setIsAddError(false)
        try {
            await updateOrganization(organization.id, name)
            setIsUpdateModelOpened(false)
            await fetchData()
        } catch (e) {
            // @ts-ignore
            if (e.response.data.message.includes("already exists")) {
                setIsAddError(true)
            }
        }

        setIsAddButtonLoading(false)
    }

    useEffect(() => {
        setIsAddButtonLoading(false)
        setIsAddError(false)
        setName(organization.name)
    }, [isUpdateModelOpened]);

    return (
        <HStack position="absolute" width="100%" style={{ marginTop: '-100px'}}>
            <DialogRoot open={isUpdateModelOpened}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Обновление организации</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                        <form>
                            <Stack width="full">
                                <Field label="Наименование организации" required color="#88898A">
                                    <Input value={name} onChange={e => setName(e.target.value)}
                                           placeholder="Наименование организации" color="#2F4F4F"/>
                                </Field>
                            </Stack>
                        </form>
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger asChild>
                            <Button variant="outline" onClick={() => setIsUpdateModelOpened(false)}
                                    disabled={isAddButtonLoading}>Отмена</Button>
                        </DialogActionTrigger>
                        <Button disabled={isAddButtonDisable} loading={isAddButtonLoading}
                                onClick={updateOrganizationHandler}>Обновить</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </HStack>
    )
}

export default UpdateOrganizationModel