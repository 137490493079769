import axios from "axios";
import {getJwt} from "../utils/tokenUtils";

export const getUsers = (page, sortBy, sortField) => {
    return axios.get(`/api/admin/users?pageNumber=${page}&sortBy=${sortBy}&sortField=${sortField}`, {headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const addUser = (user) => {
    return axios.post('/api/admin/users', user, {headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const updateUser = (id, user) => {
    return axios.post(`/api/admin/users/${id}`, user, {headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const deleteUser = (id) => {
    return axios.delete(`/api/admin/users/${id}`, {headers: {Authorization: 'Bearer ' + getJwt()}});
}
