import axios from 'axios';
import {getJwt} from "../utils/tokenUtils";

export const getKey = (page, sortBy, sortField) => {
    return axios.get(`/api/admin/activation-keys?pageNumber=${page}&sortBy=${sortBy}&sortField=${sortField}`, {headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const addKey = (organizationInn) => {
    return axios.post('/api/admin/activation-keys/generate', {organizationInn}, {headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const deleteKey = (id) => {
    return axios.delete(`/api/admin/activation-keys/${id}`, {headers: {Authorization: 'Bearer ' + getJwt()}});
}