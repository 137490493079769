// @ts-nocheck
// @ts-ignore

import {DialogContent, Flex, HStack, Table} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Button} from "./ui/button";
import {PaginationNextTrigger, PaginationPageText, PaginationPrevTrigger, PaginationRoot} from "./ui/pagination";
import {PageType} from "../type/CommonType";
import Loader from "./Loader";
import {isShowAdminContent} from "../utils/tokenUtils";
import {deleteKey, getKey} from "../api/key";
import {FaTrashCan} from "react-icons/fa6";
import AddKeyModel from "./AddKeyModel";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import moment from "moment/moment";

const status = {
    NOT_USED: "Не активирован",
    USED: "Активирован",
}

const Key = () => {
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState({content: [{id: 1}], page: {totalElements: 1}});
    const [isDeleteModelOpened, setIsDeleteModelOpened] = useState(false);
    const [isCreateModelOpened, setIsCreateModelOpened] = useState(false);
    const [deleteKeyId, setDeleteKeyId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number | null>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortDirection, setSortDirection] = useState<string>('DESC');

    const fetchData = async () => {
        setLoading(true)
        const {data} = await getKey(currentPage, sortDirection, sortField)
        setKey(data)
        setLoading(false)
    }

    // @ts-ignore
    useEffect(() => {
        fetchData().then()
    }, []);

    useEffect(() => {
        fetchData().then()
    }, [currentPage, sortDirection, sortField]);

    const handleDeleteOpenModel = (keyId: number) => {
        setDeleteKeyId(keyId)
        setIsDeleteModelOpened(true)
    }

    const handleDeleteKey = async () => {
        await deleteKey(deleteKeyId)
        setDeleteKeyId(null)
        setIsDeleteModelOpened(false)

        await fetchData().then()
    }

    const updatePage = (page: number) => {
        setCurrentPage(page - 1)
    }

    const handleSort = (fieldName: string) => {
        if (fieldName == sortField) {
            if (sortDirection === "ASC") {
                setSortDirection("DESC")
            } else {
                setSortDirection("ASC")
            }
        } else {
            setSortField(fieldName)
            setSortDirection("ASC")
        }
    }

    const isShowSort = (fieldName: string) => {
        if (fieldName == sortField) {
            return sortDirection === "DESC" ? '▲' : '▼'
        }
    }

    return (
        <Flex direction="column" gap={4}>
            <AddKeyModel
                isCreateModelOpened={isCreateModelOpened}
                setIsCreateModelOpened={setIsCreateModelOpened}
                fetchData={fetchData}
            />
            <Flex justify="end" gap={5} align="center">
                {isShowAdminContent() &&
                    <Button disabled={loading} onClick={() => setIsCreateModelOpened(true)}>Добавить ключ</Button>}
                <PaginationRoot
                    disabled={loading}
                    count={key.page.totalElements}
                    pageSize={35}
                    defaultPage={1}
                    onPageChange={(e: PageType) => updatePage(e.page)}
                >
                    <HStack gap="4">
                        <PaginationPageText flex="1"/>
                        <PaginationPrevTrigger/>
                        <PaginationNextTrigger/>
                    </HStack>
                </PaginationRoot>
            </Flex>
            {loading ?
                <Loader/> :
                <>
                    <Table.Root size="sm" interactive>
                        <Table.Header>
                            <Table.Row style={{cursor: 'pointer'}}>
                                <Table.ColumnHeader
                                    width='145px' onClick={() => handleSort('id')}>Идентификатор {isShowSort('id')}
                                </Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('key')}>Ключ {isShowSort('key')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('createdAt')}>Дата создания {isShowSort('createdAt')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('activatedAt')}>Дата активации {isShowSort('activatedAt')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('status')}>Статус {isShowSort('status')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('organization.name')}>Наименование организации {isShowSort('organization.name')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('organization.inn')}>ИНН организации {isShowSort('organization.inn')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('createdUser.lastName')}>ФИО пользователя {isShowSort('createdUser.lastName')}</Table.ColumnHeader>
                                <Table.ColumnHeader></Table.ColumnHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {key.content && key.content.map((key) => (
                                <Table.Row key={key.id}>
                                    <Table.Cell>{key.id}</Table.Cell>
                                    <Table.Cell>{key.key}</Table.Cell>
                                    <Table.Cell>{moment(key.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                    <Table.Cell>{key.activatedAt && moment(key.activatedAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                    <Table.Cell>{status[key.status]}</Table.Cell>
                                    <Table.Cell>{key.organization.name}</Table.Cell>
                                    <Table.Cell>{key.organization.inn}</Table.Cell>
                                    <Table.Cell>{`${key.createdUser.lastName} ${key.createdUser.firstName} ${key.createdUser.patronymicName}`}</Table.Cell>
                                    <Table.Cell textAlign="end">
                                        <Button variant="outline"
                                                onClick={() => handleDeleteOpenModel(key.id)}><FaTrashCan/></Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </>}
            <HStack position="absolute" width="100%">
                <DialogRoot open={isDeleteModelOpened} onInteractOutside={() => setIsDeleteModelOpened(false)}
                            placement="top">
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Удаление ключа активации</DialogTitle>
                        </DialogHeader>
                        <DialogBody>
                            <p>
                                Вы точно хотите удалить ключ активации из базы?
                            </p>
                        </DialogBody>
                        <DialogFooter>
                            <DialogActionTrigger asChild>
                                <Button variant="outline" onClick={() => setIsDeleteModelOpened(false)}>Отмена</Button>
                            </DialogActionTrigger>
                            <Button onClick={handleDeleteKey}>Удалить</Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogRoot>
            </HStack>
        </Flex>
    )
}

export default Key