import {createListCollection} from "@chakra-ui/react";
import {jwtDecode} from "jwt-decode";

export const getJwt = () => {
    return JSON.parse(localStorage.getItem('jwt'));
}

export const setJwt = (jwt) => {
    localStorage.setItem('jwt', JSON.stringify(jwt));
}

export const removeJwt = () => {
    localStorage.removeItem('jwt');
}

export const decodeJwt = (fieldName) => {
    return localStorage.getItem('jwt') && jwtDecode(localStorage.getItem('jwt'))[fieldName]
}

export const isShowAdminContent = () => {
    // @ts-ignore
    return localStorage.getItem('jwt') && decodeJwt('role')[0] === "ROLE_ADMINISTRATOR";
}

export const getUserRole = () => {
    return createListCollection({
        items: [
            {label: "Начальник лаборатории", value: "HEAD_OF_LABORATORY"},
            {label: "Поверитель", value: "VERIFIER"},
            isShowAdminContent() && {label: "Главный администратор", value: "ADMINISTRATOR"}
        ]
    })
}