import {useNavigate} from "react-router-dom";
import {decodeJwt, removeJwt} from "../utils/tokenUtils";
import {Flex, Text} from "@chakra-ui/react";
import React from "react";
import {Button} from "./ui/button";

const Header = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        removeJwt()
        navigate("/login")
    }

    const getUserRole = () => {
        const userRole = {
            ROLE_HEAD_OF_LABORATORY: "Начальник лаборатории",
            ROLE_VERIFIER: "Поверитель",
            ROLE_ADMINISTRATOR: "Главный администратор"
        }

        // @ts-ignore
        return userRole[decodeJwt('role')[0]]
    }



    return (
        <Flex width="100%" justify="space-between" paddingX="6">
            <Flex align="center" justify="start">
                <Text textStyle="3xl" fontWeight="bold">ZAMER ADMIN</Text>
            </Flex>
            <Flex gap="3">
                <Flex direction="column">
                    <Text textStyle="sm" fontWeight="bold">Логин: {decodeJwt('sub')}</Text>
                    <Text textStyle="sm" fontWeight="bold">Роль: {getUserRole()}</Text>
                </Flex>
                <Button onClick={handleLogout}>Выйти</Button>
            </Flex>
        </Flex>
    )
}

export default Header