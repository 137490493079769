import React, {useState} from "react";
import {Navigate} from 'react-router'
import {Flex, Box, Heading, Stack, Input, Text} from "@chakra-ui/react";
import {FaEye, FaEyeSlash} from "react-icons/fa6";
import {authUserApi} from "../api/auth";
import {getJwt, setJwt} from "../utils/tokenUtils";
import {Field} from "../components/ui/field";
import {InputGroup} from "../components/ui/input-group";
import {Button} from "../components/ui/button";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleLogin = async () => {
        setLoginError(false);
        setLoginLoading(true);
        try {
            const {data, status} = await authUserApi(login, password);
            if (status === 200) {
                setJwt(data.jwt)
            }
        } catch (e) {
            setLoginError(true);
        }
        setLoginLoading(false);
    }

    if (getJwt()) {
        return <Navigate to="/admin-panel"/>;
    }

    return (<>
            <Flex
                flexDirection="column"
                width="100wh"
                height="100vh"
                backgroundColor="#F6F6F6"
                justifyContent="center"
                alignItems="center">
                <Heading justifyContent="center">Добро пожаловать<br/>в админ панель базы проверок</Heading>
                <Box minW={{base: "90%", md: "468px"}}>
                    <form>
                        <Stack p="1rem" gap="4" width="full">
                            <Field label="Введите логин" required color="#88898A" errorText="This field is required">
                                <Input placeholder="Логин" value={login} onChange={e => setLogin(e.target.value)}
                                       color="#2F4F4F"/>
                            </Field>
                            <Field label="Введите пароль" required color="#88898A">
                                <InputGroup endElement={
                                    showPassword ? <FaEyeSlash cursor="pointer" onClick={handleShowPassword}/>
                                        : <FaEye cursor="pointer" onClick={handleShowPassword}/>}>
                                    <Input type={showPassword ? "text" : "password"} placeholder="Пароль"
                                           value={password}
                                           onChange={e => setPassword(e.target.value)} color="#2F4F4F"/>
                                </InputGroup>
                            </Field>
                            {loginError ? <Text textStyle="sm" color="red">Неверный логин или пароль, попробуйте еще
                                раз</Text> : null}
                            <Button disabled={login.trim() === "" || password.trim() === ""} loading={loginLoading}
                                    onClick={handleLogin}>Войти</Button>
                        </Stack>
                    </form>
                </Box>
            </Flex></>
    )
}

export default Login;